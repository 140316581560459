<template>
  <div class="building">
    <div class="text-center mt-5 mb-4">
      <v-btn
        class=""
        color="success"
        rounded
        @click.prevent="submit"
        :disabled="!isValid"
        :loading="loading"
      >
        <v-icon>fa-check</v-icon>
      </v-btn>
      <div class="caption font-weight-light">Guardar</div>
    </div>
    <v-card class="mx-auto">
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-office-building-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="headline">
            Identificación visual
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-form ref="form">
            <v-text-field
              label="Nombre"
              v-model="buildingName"
              type="text"
              :rules="nameRules"
              counter
              :color="whiteLabel.colors.primary"
              @input="inputChanged"
              hint="Nombre a mostrar en pantalla"
            ></v-text-field>
            <v-text-field
              label="Dirección"
              v-model="buildingAddress"
              type="text"
              :rules="addressRules"
              error-count="2"
              counter
              :color="whiteLabel.colors.primary"
              required
              @input="inputChanged"
              hint="Dirección a mostrar en pantalla"
            ></v-text-field>
          </v-form>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <v-card class="mx-auto mt-8" v-if="license && license.virtual">
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-crosshairs-gps</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="headline">
            Ubicación del QR
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-alert dismissible elevation="0" outlined text type="info" :color="whiteLabel.colors.primary"
            ><span
              >Para definir la ubicacióna del QR, ingrese una Dirección en Mapa,
              toque el botón celeste de Identificación visual o Geolocalización,
              o arrastre el Marcador en el mapa.</span
            >
          </v-alert>

          <v-row no-gutters align="center" justify="space-between">
            <v-col cols="9" md="10" sm="8">
              <GmapAutocomplete class="introInput" @place_changed="setPlace">
                <template v-slot:input="slotProps">
                  <v-text-field
                    style="width: 100%"
                    label="Dirección en Mapa"
                    prepend-inner-icon="mdi-map-marker"
                    placeholder="Ingrese una dirección para posicionar el marcador"
                    hint="Ingrese una dirección para la ubicación o arrastre el marcador a un punto en el mapa."
                    ref="input"
                    v-model="autoComplete"
                    v-on:listeners="slotProps.listeners"
                    v-on:attrs="slotProps.attrs"
                    :color="whiteLabel.colors.primary"
                  >
                    ></v-text-field
                  >
                </template>
              </GmapAutocomplete>
            </v-col>
            <v-col cols="3" md="2" sm="4" align="center">
              <v-btn
                fab
                small
                dark
                :color="whiteLabel.colors.primary"
                class="ml-2 mb-2"
                @click="setAutocomplete"
                ><v-icon>mdi-office-building-outline</v-icon></v-btn
              >
              <v-btn
                fab
                small
                dark
                :color="whiteLabel.colors.primary"
                class="ml-2 mb-2"
                @click="geolocate"
                ><v-icon>mdi-crosshairs-gps</v-icon></v-btn
              >
            </v-col>
          </v-row>

          <GmapMap
            ref="map"
            :center="location"
            :zoom="zoomLevel"
            map-type-id="terrain"
            style="width: 100%; height: 300px"
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUi: false,
              styles: [
                {
                  featureType: 'poi',
                  stylers: [
                    {
                      visibility: 'off',
                    },
                  ],
                },
                {
                  featureType: 'transit',
                  stylers: [
                    {
                      visibility: 'off',
                    },
                  ],
                },
              ],
            }"
          >
            <GmapMarker
              :position="location"
              :clickable="false"
              :draggable="true"
              @dragend="updateCoordinates"
            />

            <GmapCircle
              :center="location"
              :radius="range"
              v-if="!unlimited"
              :options="{
                strokeColor: 'green',
                fillColor: 'green',
                fillOpacity: 0.3,
                strokeOpacity: 1,
                strokeWeight: 0.8,
              }"
            ></GmapCircle>
          </GmapMap>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-radio-tower</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="headline">
            Rango de Alcance
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-row no-gutter>
            <v-col lg="3" md="4" sm="12" cols="12">
              <v-switch
                :input-value="unlimited"
                color="green"
                inset
                class="pl-4"
                label="Ilimitado"
                @change="checkUnlimited"
              ></v-switch>
            </v-col>
            <v-col lg="9" md="8" sm="12" cols="12">
              <v-slider
                v-model="range"
                :disabled="unlimited"
                @change="inputChanged"
                max="500"
                min="25"
                label="Alcance"
                thumb-label="always"
                thumb-size="24"
                color="green"
                :track-color="whiteLabel.colors.secondary"
                hint="Rango de Alcance (en metros)"
                persistent-hint
                class="mt-5"
              ></v-slider>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <v-snackbar
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { db } from "@/fb";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    loading: false,
    isValid: false,
    buildingName: "",
    buildingAddress: "",
    nameRules: [(v) => v.length <= 25 || "Largo máximo es 25 caracteres"],
    addressRules: [
      (v) => !!v || "Dirección es obligatorio",
      (v) => v.length <= 25 || "Largo máximo es 25 caracteres",
    ],
    snackbar: {
      visible: false,
      timeout: 2000,
      text: "",
      color: "",
    },

    location: {
      lat: 0,
      lng: 0,
    },
    range: 25,
    zoomLevel: 17,
    autoComplete: null,
    unlimited: true,
  }),

  mounted() {
    this.snackbar.visible = true;
    this.snackbar.text = "Cargando datos ...";
    this.snackbar.color = "success";
    this.getBuilding();
  },

  computed: {
    ...mapGetters({
      user: "user",
      license: "license",
      whiteLabel: "whiteLabel",
    }),
  },

  methods: {
    async getBuilding() {
      try {
        await db
          .doc("buildings/" + this.user.data.building)
          .get()
          .then((building) => {
            this.buildingName = building.data().name;
            this.buildingAddress = building.data().address;
            this.location.lat = building.data().location
              ? building.data().location.lat
              : 0;
            this.location.lng = building.data().location
              ? building.data().location.lng
              : 0;
            this.range = building.data().location
              ? building.data().location.range
              : 0;
            if (this.range == 0) {
              this.unlimited = true;
            } else {
              this.unlimited = false;
            }
            this.$forceUpdate();
          });
      } catch (error) {
        console.log(error);
      }
    },

    inputChanged() {
      if (this.$refs.form.validate()) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    },

    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          this.snackbar.visible = true;
          this.snackbar.text = "Guardando datos ...";
          this.snackbar.color = "success";
          await db
            .doc("buildings/" + this.user.data.building)
            .update({
              name: this.buildingName,
              address: this.buildingAddress,
              location: {
                lat: this.location.lat,
                lng: this.location.lng,
                range: this.unlimited ? 0 : this.range,
              },
            })
            .then(() => {
              this.loading = false;
              this.isValid = false;
              this.license.buildingName = this.buildingName;
              this.license.buildingAddress = this.buildingAddress;
            });
        } catch (error) {
          console.log(error);
        }
      }
    },

    checkUnlimited() {
      this.unlimited = !this.unlimited;
      if (this.unlimited) {
        this.range = 0;
      }
      this.inputChanged();
    },

    updateCoordinates(location) {
      this.location = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      this.zoomLevel = 17;
      this.inputChanged();
    },

    setPlace(place) {
      this.location.lat = place.geometry.location.lat();
      this.location.lng = place.geometry.location.lng();
      this.zoomLevel = 17;
      this.inputChanged();
    },

    setAutocomplete() {
      this.autoComplete = this.buildingAddress;
      setTimeout(() => this.$refs.input.focus(), 300);
    },

    geolocate() {
      this.loading = true;
      this.snackbar.visible = true;
      this.snackbar.text = "Geolocalizando ...";
      this.snackbar.color = "info";

      navigator.geolocation.getCurrentPosition((position) => {
        this.location.lat = position.coords.latitude;
        this.location.lng = position.coords.longitude;
        this.loading = false;
        this.inputChanged();
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
